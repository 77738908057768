<template>
  <view-base ref="base">
    <div class="mb-3">
      <div class="row">
        <button class="btn btn-primary mr-2" @click="gotoPrevWeek">
          anterior
        </button>
        <h4><b>Semana do dia {{ startDate }} à {{ endDate }}</b></h4>
        <button class="btn btn-primary ml-2" @click="gotoNextWeek">
          próxima
        </button>
      </div>
    </div>
    <div class="mb-3">
      <h4><b>Entregas realizadas</b> {{ totalDeliveries }}</h4>
      <div class="row">
        <b>Faturado: {{ totalBillable }}€</b>
      </div>
    </div>
    <div class="mb-3">
      <h4><b>Estafetas</b> ({{ pendingPayments.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsPendingPayments"
          :values="pendingPayments"
          :paginated="false"
          :show-filter="false"
          :on-select-item="onPaymentSelected"
          :filter-case-sensitive="false"
          empty-message="Nenhum valor pendente até o momento"
        />
      </div>
    </div>
    <div class="mb-3">
      <h4><b>Últimos pagamentos </b> ({{ lastPayments.length }})</h4>
      <div class="row">
        <advanced-table
          :columns="columnsLastPayments"
          :values="lastPayments"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          empty-message="Nenhuma pagamento realizado nos últimos 30 dias"
        />
      </div>
    </div>
    <div v-show="isModalVisible" class="modal-backdrop">
      <div class="modal">
        <header class="modal-header">
          <slot name="header">
            Novo Pagamento
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
          >
            x
          </button>
        </header>

        <section class="modal-body">
          <slot name="body">
            Confirma o pagamento abaixo?
            <br><br>
            Estafeta: {{ currentDriver }}
            <br><br>
            Valor: {{ currentAmount }}€
            <br><br>
            IBAN: {{ currentIBAN }}
          </slot>
        </section>

        <footer class="modal-footer">
          <button
            type="button"
            class="btn-red"
            @click="closeModal"
          >
            Cancelar pagamento
          </button>
          <button
            type="button"
            class="btn-green"
            @click="onPaymentCreated"
          >
            Confirmar pagamento
          </button>
        </footer>
      </div>
    </div>
  </view-base>
</template>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.modal-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1 !important;
    position: fixed;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    margin: 10%;
    width: 80%;
    height: 80%;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #0a42ae;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
  }

  .btn-green {
    color: white;
    background-color: #0a42ae;
    border: 1px solid #0a42ae;
    border-radius: 2px;
  }

  .btn-red {
    color: white;
    background-color: red;
    border: 1px solid red;
    border-radius: 2px;
  }
</style>

<script>
import Ripple from 'vue-ripple-directive'

import axios from 'axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'
import { getUserData } from '@/auth/utils'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    ViewBase,
    AdvancedTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentWeek: 0,
      startDate: moment().startOf('week').add(1, 'days').add(this.currentWeek * 7, 'days')
      .format('DD/MM/YYYY'),
      endDate: moment().endOf('week').add(1, 'days').add(this.currentWeek * 7, 'days')
      .format('DD/MM/YYYY'),
      isModalVisible: false,
      totalDeliveries: 0,
      pendingPayments: [],
      lastPayments: [],
      currentDriver: '',
      currentAmount: '',
      currentIBAN: '',
      userRole: getUserData().role.code,
      columnsPendingPayments: [
        {
            title: 'Estafeta',
            name: 'driver',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
          title: 'Entregas',
          name: 'deliveries',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
        },
        {
            title: 'Valor',
            name: 'amountFormated',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'IBAN',
            name: 'iban',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      columnsLastPayments: [
        {
            title: 'Estafeta',
            name: 'driver',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Hora',
            name: 'formatDate',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'Valor',
            name: 'amountFormated',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
        {
            title: 'IBAN',
            name: 'iban',
            visible: true,
            sortable: true,
            cellstyle: 'table-cell-bold-sigla',
        },
      ],
      sortColunms: [],
      filteredSize: 0,
    }
  },
  mounted() {
    if (!(getUserData().email === 'danilo@eatz.pt' || getUserData().email === 'gestor@eatz.pt')) {
      this.$router.push({ name: 'not-authorized' })
      return
    }
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      const self = this
      this.$refs.base.showLoading()
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/drivers/pendingPayments?startDate=${this.startDate}&endDate=${this.endDate}`, useJwt.getHeader())
      .then(response => {
        self.pendingPayments = response.data.pendingPayments ?? []
        self.lastPayments = response.data.lastPayments ?? []
        self.totalDeliveries = response.data.totalDeliveries ?? 0
        self.totalBillable = parseFloat(response.data.totalBillable.cost ?? 0).toFixed(2)
        self.pendingPayments.forEach((order, index) => {
          self.pendingPayments[index].amountFormated = `${self.pendingPayments[index].amount}€`
        })
        self.lastPayments.forEach((order, index) => {
          self.lastPayments[index].formatDate = moment(self.lastPayments[index].created_at).format('DD/MM/yy HH:mm')
          self.lastPayments[index].amount = parseFloat(self.lastPayments[index].amount).toFixed(2)
          self.lastPayments[index].amountFormated = `${self.lastPayments[index].amount}€`
        })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    gotoPrevWeek() {
      this.currentWeek -= 1
      this.updateWeek()
    },
    gotoNextWeek() {
      this.currentWeek += 1
      this.updateWeek()
    },
    updateWeek() {
      this.startDate = moment().startOf('week').add(1, 'days').add(this.currentWeek * 7, 'days')
      .format('DD/MM/YYYY')
      this.endDate = moment().endOf('week').add(1, 'days').add(this.currentWeek * 7, 'days')
      .format('DD/MM/YYYY')
      this.loadData()
    },
    onPaymentSelected(event, data) {
      this.isModalVisible = true
      this.currentDriver = data.driver
      this.currentDriverId = data.driver_id
      this.currentIBAN = data.iban
      this.currentAmount = data.amount
    },
    onPaymentCreated() {
      const data = {
        startDate: this.startDate,
        endDate: this.endDate,
        driver_id: this.currentDriverId,
        amount: this.currentAmount,
        iban: this.currentIBAN,
      }
      axios.defaults.headers.common.Accept = 'application/json'
      axios.defaults.headers.common.Authorization = `Bearer ${window.$cookies.get('access_token')}`
      this.$refs.base.showLoading()
      axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/drivers/addPayment`, data)
      .then(() => {
        this.$refs.base.showToast(this.$t('Pagamento registado com sucesso'))
        this.loadData()
        this.closeModal()
      })
      .catch(error => {
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
